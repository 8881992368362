<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="yearCollection"
          :search="search"
          :sort-by="['id']"
          sort-desc="true"
          :loading="loadingTable"
          @click:row="handleClickItem"
          :footer-props="{
            'items-per-page-options': [100],
          }"
        >
          <template #header="{ props: { headers } }">
            <thead class="v-data-table-header">
              <tr>
                <th></th>
                <th>
                  <v-select
                    v-model="statusFilter"
                    :items="['Minden státusz', ...statusCollection]"
                  ></v-select>
                </th>

                <th></th>
              </tr>
            </thead>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <YearModalForm
                :modalData="modalData"
                :permissions="permissions"
                :yearCollection="yearCollection"
                :statuses="statuses"
                :endPoint="endPoint"
                @closeModalForm="handleCloseModalForm"
                @saveModalForm="handleSaveModalForm"
                @new="handleNew"
              ></YearModalForm>
              <v-spacer></v-spacer>
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>
          <template v-slot:item.status="{ item }">
            {{ item.status }}
          </template>
          <template v-slot:item.translated="{ item }">
            <v-icon v-if="item.translated" color="green" small
              >check_circle</v-icon
            >
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="primary"
              v-if="permissionCan('delete')"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// import SiteService from "@/core/services/site.service.js";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

import YearModalForm, { initialFormData } from "./YearModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

export const PERMISSION_TO = "humanResources.year.";

export default {
  name: "Years",
  components: { YearModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("MENU.RED_LETTER_DAYS"),
      search: "",
      routePath: "/humanResource/redletterdays/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: this.$t("TABLE.year"),
          value: "year",
        },
        // {
        //   text: this.$t("TABLE.company"),
        //   value: "company",
        // },
        {
          text: "Státusz",
          value: "status",
          width: "30%",
          filter: (value) => {
            if (this.statusFilter == "Minden státusz") return true;
            return value == this.statusFilter;
          },
        },
        {
          text: this.$t("TABLE.actions"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
      statusFilter: "Aktív",
      statusCollection: ["Aktív", "Inaktív", "Lezárt"],
    };
  },

  computed: {
    ...mapGetters(["yearCollection", "statuses"]),

    endPoint() {
      return (
        // "quotations/admin/" +
        // SiteService.getActiveSiteId() +
        "humanResources/year/"
      );
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchYear"]),
    fetchModel() {
      return this.fetchYear();
    },

    handleSaveModalForm(item = null) {
      if (item) {
        item.id = item.year;
        this.handleClickItem(item);
      }
    },
  },

  mounted() {
    this.loadingTable = true;
    this.fetchModel().then(() => (this.loadingTable = false));
  },
};
</script>
