<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="950px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("MENU.RED_LETTER_DAY") }}
      </v-btn>
    </template>

    <v-card min-height="50vh">
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-col cols="2" v-if="formModel && formModel.id">
              <v-text-field
                v-model="formModel.year"
                :label="$t('FORM_INPUT_NAMES.year')"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="2" v-if="formModel && !formModel.id">
              <v-select
                v-model="formModel.year"
                :items="computedYears"
                chips
                :label="$t('FORM_INPUT_NAMES.year')"
                solo
              >
              </v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.humanresources"
                v-model="formModel.status"
                :items="statuses.humanresources.year"
                :label="$t('FORMS.status')"
                item-text="value"
                item-value="key"
              >
                <template slot="selection" slot-scope="slotProps">
                  {{ $t("STATUSES." + slotProps.item.value) }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="navi-text">{{
                    $t("STATUSES." + slotProps.item.value)
                  }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <!-- {{ inputData }} -->
            <v-col cols="3" class="mt-auto mb-2">
              <label for="datePicker">{{ $t("FORM_INPUT_NAMES.date") }}</label>

              <b-form-datepicker
                v-if="formModel.year"
                id="datePicker"
                v-model="inputData.day"
                :start-weekday="1"
                class="mb-2"
                @context="onContext"
                :date-disabled-fn="dateDisabled"
                locale="hu"
                :min="new Date(formModel.year, 0, 1)"
                :max="new Date(formModel.year, 11, 31)"
                :disabled="formModel.status == 2"
              ></b-form-datepicker>
            </v-col>
            <v-col cols="4" v-if="inputData.day" class="mt-auto">
              <v-text-field
                v-model="inputData.description"
                :label="$t('FORM_INPUT_NAMES.description')"
              ></v-text-field>
            </v-col>
            <v-col cols="3" v-if="inputData.day" class="mt-auto">
              <v-select
                v-model="inputData.type"
                :items="dayTypes"
                item-text="value"
                item-value="key"
                @change="handleChangeType"
              >
              </v-select>
            </v-col>
            <v-col cols="2" v-if="inputData.day" class="mt-auto mb-5">
              <v-btn
                v-if="inputData.day"
                color="primary"
                elevation="2"
                @click="handleAddInputData"
              >
                {{ $t("FORMS.add") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <table v-if="this.formModel.special_days.length > 0">
                <tr>
                  <td>N</td>
                  <td>Dátum</td>
                  <td>Leírás</td>
                  <td>Típus</td>
                  <td>{{ $t("TABLE.actions") }}</td>
                </tr>
                <tr v-for="(day, index) of specialDays" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{
                      new Date(day.day).toLocaleString("hu-HU", {
                        timeZone: "UTC",
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })
                    }}
                  </td>
                  <td>{{ day.description }}</td>
                  <td>
                    {{ dayTypes.find((item) => item.key == day.type).value }}
                  </td>
                  <td>
                    <v-icon
                      color="primary"
                      small
                      @click="deleteItem(index)"
                      :disabled="formModel.status == 2"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn> -->
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
      <SnackBarInfoComponent :snackbarInfo="snackbarInfo">
      </SnackBarInfoComponent>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { PERMISSION_TO } from "./Year";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
// import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";
// import draggable from "vuedraggable";
import SnackBarInfoComponent from "@/view/components/SnackBarInfoComponent";

const TRANSLATED_ATRIBUTES = [];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [];

export const initialFormData = () => ({
  id: null,
  year: null,
  status: 1,
  special_days: [],
  translations: {},
  custom_fields: [],
});

export const initialInputData = () => ({
  id: null,
  day: null,
  description: null,
  non_working_day: 1,
  type: 2,
});

export default {
  name: "YearForm",
  props: ["modalData", "permissions", "statuses", "endPoint", "yearCollection"],
  components: { SnackBarInfoComponent },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],

      inputData: initialInputData(),

      // minDate: new Date(now.getFullYear(), 1, 1),
      // maxDate: new Date(now.getFullYear(), 12, 31),

      dayTypes: [
        {
          key: 1,
          value: "Áthelyezett munkanap",
        },
        {
          key: 2,
          value: "Munkaszüneti nap",
        },
        {
          key: 3,
          value: "Áthelyezett pihenőnap",
        },
      ],
    };
  },
  computed: {
    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") + this.formModel.year
        : this.$t("MENU.NEW") + " " + this.$t("MENU.RED_LETTER_DAY");
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },

    specialDays() {
      if (!this.formModel || !this.formModel.special_days) return [];

      let specialDays = this.formModel.special_days;

      specialDays.sort((a, b) => (a.day > b.day ? 1 : b.day > a.day ? -1 : 0));

      return specialDays;
    },

    computedYears() {
      let usedYears = this.yearCollection.map((y) => y.year);
      let computedYears = [];
      let currentYear = new Date().getFullYear();

      while (computedYears.length < 10) {
        if (!usedYears.includes(1 * currentYear)) {
          computedYears.push(currentYear);
        }
        currentYear++;
      }

      return computedYears;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.inputData = initialInputData();
        this.selectedLocale = i18nService.userLanguages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });

        if (value.editedId) {
          this.loader = true;
          this.formModel = initialFormData();
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              // data.year = data.year.substring(0, 10);
              data.id = 1 * data.year;
              if (!data.special_days) {
                data.special_days = [];
              } else {
                data.special_days.map((item) => {
                  item.day = item.day.substring(0, 10);
                  return item;
                });
              }
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
              // this.formModel.custom_fields = this.initCustomFields();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = initialFormData();
          this.setTranslatedAttributes();

          // this.formModel.custom_fields = this.initCustomFields();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
    // "formModel.quotations": function (newVal) {
    //   this.files = this.calculateFiles(newVal);
    // },
  },
  methods: {
    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );
      // model.quotations = this.files.map((file) => file.id);
      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;

        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.showSnackBarInfo();
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(({ data }) => {
              this.showSnackBarInfo();
              this.$emit("saveModalForm", data);
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    handleAddInputData() {
      if (
        !this.formModel.special_days
          .map((d) => d.day)
          .includes(this.inputData.day)
      ) {
        this.formModel.special_days.push(this.inputData);
      }

      this.inputData = initialInputData();
    },

    //date picker
    onContext(ctx) {
      this.inputData.day = ctx.selectedYMD;
    },

    deleteItem(index) {
      this.formModel.special_days.splice(index, 1);
    },

    dateDisabled(ymd) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      // const weekday = date.getDay();
      // const day = date.getDate();
      // // Return `true` if the date should be disabled
      // return weekday === 0 || weekday === 6 || day === 13;

      return this.formModel.special_days.map((i) => i.day).includes(ymd);
    },

    handleChangeType() {
      if ([2, 3].includes(this.inputData.type)) {
        this.inputData.non_working_day = 1;
      } else {
        this.inputData.non_working_day = 0;
      }
    },
  },

  mounted() {
    this.setTranslatedAttributes();
  },
};
</script>

<style lang="css" scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
